import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'

interface PromotionListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  promotions: any[];

  cities: any[];
  categories: any[];
}

const $state: PromotionListState = {
  loading: false,
  loaded: false,
  fatalError: false,

  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  promotions: [],

  cities: [],
  categories: [],


}

export const $actions: ActionTree<PromotionListState, RootState> = {
  loadData({ commit, getters, dispatch, state }, query) {
    const vm = (this as any)._vm
    const params: any = {}

    if (query.sortField) {
      params.order_by = query.sortField
      params.order_direction = query.sortDirection || 'asc'
    }
    if (query.start_date) {
      params.start_date = query.start_date
    }
    if (query.end_date) {
      params.end_date = query.end_date
    }
    if (query.active_only > 0) {
      params.active_only = query.active_only
    }
    if (query.cities > 0) {
      params.cities = JSON.stringify(query.cities)
    }

    params.page = query.page || 1
    params.per_page = 10

    return new Promise((resolve, reject) => {
      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/promotion', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  loadVocab({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/cities`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_CITIES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
      vm.$http
        .get('/admins/rest/v1/product_categories')
        .then((response: AxiosResponse) => {
          commit('SET_CATEGORIES_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve(response)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },

  changeFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
  },
}

export const $mutations: MutationTree<PromotionListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },

  SET_ITEMS_LIST(state, data) {
    state.promotions = data.promotions

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },

  SET_CATEGORIES_LIST(state, data) {
    state.categories = data.product_categories
  },
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  },
  CHANGE_VALUE(state: PromotionListState, { field, newValue }) {
    // @ts-ignore
    state.filter[field] = newValue
  },
}

export const $getters: GetterTree<PromotionListState, RootState> = {}

export const STORE_KEY = '$_promotion_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
