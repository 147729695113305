

























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { STORE_KEY, store } from './_store'

import TitleBar from '../../components/Elements/TitleBar.vue'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'fatalError', 'categories', 'cities', 'promotions',
      'lastMeta',
    ]),
  },
  methods: {},
  components: {
    TitleBar,
  },
})
export default class PromotionList extends Vue {

  public showModal: any = false
  public selectedOptions: any = []
  public filter: any = {
    active_only: false,
    start_date: null,
    end_date: null,
    cities: [],
  }

  get titleStack() {
    return [
      'Акции',
    ]
  }

  public changeValue(value: any) {
    this.filter.cities = value
  }

  get defaultSort() {
    return [this.$route.query.sortField || 'name', this.$route.query.sortDirection || 'asc']
  }

  public onSort(field: string, direction: string) {
    this.$router.replace({
      query: {
        ...this.$route.query, sortField: field, sortDirection: direction,
      },
    })
  }

  public onClearSearch() {
    this.$router.replace({
      query: {},
    })
    this.showModal = !this.showModal
  }

  public onSearch() {
    const filter: any = {}
    for (const key of Object.keys(this.filter)) {
      if (this.filter[key]) {
        if (key === 'cities') {
          filter.cities = this.filter[key].map((c: any) => c.id)
        }
        else {
          filter[key] = this.filter[key]
        }
      }
    }
    this.$router.replace({
      query: {
        ...this.$route.query, ...filter,
      },
    })
    this.showModal = !this.showModal
  }

  public onPageChange(page: string) {
    this.$router.replace({
      query: { ...this.$route.query, page },
    })
  }

  public tableClick(row: any) {
    this.$router.push({ name: 'promotion-edit', params: { id: row.id } })
  }

  // ---------------------------------------------------------------------------

  @Watch('$route.query')
  public handleParamsChange() {
    for (const key of Object.keys(this.$route.query)) {
      if (this.$route.query[key]) {
        if (key === 'cities') {
          // filter['cities'] = this.filter[key].map((c) => c.id)
        }
        else {
          this.filter[key] = this.$route.query[key]
        }
      }
    }
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.$route.query)
  }

  public handleLoadVocab() {
    this.$store.dispatch(`${STORE_KEY}/loadVocab`)
  }


  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.handleParamsChange()
    this.handleLoadVocab()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
